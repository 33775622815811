import React, { Component } from 'react'
import { Page, PageHeader, Actions, PageBody } from '../../components/Layout';

import FileList from '../../components/files/FileList'
import DeleteButton from '../../components/files/DeleteButton';

class FileBrowser extends Component {
  state = {
    selected: null
  }

  onClick = (selected) => {
    if (this.state.selected === selected) {
      this.setState({ selected: null })
    } else {
      this.setState({ selected })
    }
  }

  onClearClick = () => {
    this.setState({ selected: null })
  }

  render() {
    return (
      <Page>
        <PageHeader>
          <h1>Files</h1>
          <Actions>
            {this.state.selected &&
              <DeleteButton id={this.state.selected} />
            }
          </Actions>
        </PageHeader>
        <PageBody>
          <FileList
            page={this.props.page}
            selected={this.state.selected}
            onClick={this.onClick}
            onClearClick={this.onClearClick}
          />
        </PageBody>
      </Page>
    )
  }
}

export default FileBrowser
