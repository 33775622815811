import React, { Component } from 'react';
import NotificationSystem from 'react-notification-system';
import Button from './Button';

// TODO style notification:
// https://github.com/igorprado/react-notification-system/blob/master/src/styles.js

class SaveButton extends Component {
  notificationSystem = React.createRef()

  state = {
    error: false,
    saving: false
  }

  onClick = (e) => {
    const notification = this.notificationSystem.current;

    this.setState({ error: false, saving: true })

    let data = this.props.data

    if (data instanceof Function) {
      data = data()
    }

    this.props.mutate({
      variables: {
        id: this.props.id,
        attributes: data
      }
    }).then((result) => {
      this.setState({ saving: false })

      notification.addNotification({
        message: 'Saved content',
        level: 'success',
        autoDismiss: 1
      })

      if (this.props.onSave) {
        this.props.onSave(result.data)
      }
    }).catch((e) => {
      console.error(e)

      this.setState({ error: true, saving: false })

      notification.addNotification({
        message: 'Could not save content',
        level: 'error',
        autoDismiss: 1
      })
    })
  }

  render() {
    return (
      <React.Fragment>
        <Button disabled={this.state.saving} error={this.state.error} onClick={this.onClick}>Save</Button>
        <NotificationSystem ref={this.notificationSystem} />
      </React.Fragment>
    )
  }
}

export default SaveButton
