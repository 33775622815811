import React from 'react'
import classNames from 'classnames'

import styles from './RichTextEditor.module.css'

export default ({ attributes, children, tag, isSelected }) => (
  React.createElement(
    tag,
    { className: classNames(styles.block, { [styles.active]: isSelected }), ...attributes},
    children
  )
)
