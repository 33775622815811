import React from 'react';
import { Page, PageHeader, PageBody } from './Layout';

const Blank = ({ title }) => (
  <Page>
    <PageHeader>
      <h1>{title}</h1>
    </PageHeader>
    <PageBody>
    </PageBody>
  </Page>
)

export default Blank
