import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from "graphql-tag";

import { loginUrl } from '../urls'
import Loading from '../icons/Loading';

import styles from './Authenticate.module.css';

const query = gql`
  query Viewer {
    account {
      name
    }
    viewer {
      name
    }
  }
`;

const Backdrop = ({ children }) => (
  <div className={styles.wrapper}>{children}</div>
)

const Message = ({ children }) => (
  <Backdrop>
    <div className={styles.content}>{children}</div>
  </Backdrop>
)

const Authenticate  = ({ children }) => {
  const { loading, error, data } = useQuery(query)

  if (loading) {
    return <Backdrop><Loading color='#fff' /></Backdrop>
  }

  if (error) {
    return <Message>An error occured!</Message>
  }

  if (!data.viewer) {
    return <Message>Please <a href={loginUrl}>log in</a></Message>
  }

  return (
    <React.Fragment>
      {children(data)}
    </React.Fragment>
  )
}

export default Authenticate
