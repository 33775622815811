import React, { Component } from 'react'

import styles from './LocaleSelector.module.css'

class LocaleSelector extends Component {
  onClick = (e) => {
    this.props.onChange('locale', e.target.innerText)
  }

  render () {
    const locales = ['en', 'fr']

    return (
      <div className={styles.wrapper}>
        {locales.map(locale => (
          <span key={locale}>
            {locale === this.props.locale ? (
              <button disabled className={styles.button}>{locale}</button>
            ) : (
              <button className={styles.button} onClick={this.onClick}>{locale}</button>
            )}
          </span>
        ))}
      </div>
    )
  }
}

export default LocaleSelector;
