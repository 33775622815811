import React from 'react';
import { Route } from "react-router-dom";

import Blank from '../../components/Blank';
import { Main, Body } from '../../components/Layout';
import ContentTypesMenu from './ContentTypesMenu';
import ContentTypesEditor from './ContentTypesEditor';

export default () => (
  <Main>
    <ContentTypesMenu />
    <Body>
      <Route path='/content-types' exact render={() => (
        <Blank title='Content Types' />
      )} />
      <Route path='/content-types/:id' render={({ history, match }) => (
        <ContentTypesEditor history={history} id={match.params.id} />
      )} />
    </Body>
  </Main>
)
