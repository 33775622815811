import React from 'react';
import { Route } from "react-router-dom";

import { Main, Body } from '../../components/Layout';
import Blank from '../../components/Blank';
import PagesMenu from './PagesMenu';
import PagesEditor from './PageEditor';

export default ({ match }) => {
  const { siteId } = match.params

  return (
    <Main>
      <PagesMenu siteId={siteId} />
      <Body>
        <Route path='/sites/:siteId/pages' exact render={() => (
          <Blank title='Pages' />
        )} />
        <Route path='/sites/:siteId/pages/:id' render={({ history, match }) => (
          <PagesEditor history={history} id={match.params.id} siteId={match.params.siteId} />
        )} />
      </Body>
    </Main>
  )
}
