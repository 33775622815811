import React, { Component } from 'react';
import { graphql } from "react-apollo";
import gql from "graphql-tag";

import Button from '../../components/Button';

class DeleteButton extends Component {
  onClick = (e) => {
    this.props.mutate({
      variables: {
        id: this.props.id
      }
    })
  }

  render() {
    return (
      <Button onClick={this.onClick}>Delete</Button>
    )
  }
}

const mutation = gql`
  mutation DeletedFile($id: ID!) {
    deleteFile(id: $id) {
      id
    }
  }
`;

const refetchQueries = ['Files']

export default graphql(mutation, { options: { refetchQueries }})(DeleteButton)
