import each from 'lodash/map';
import serializer from '../components/editors/serializer';

function deserializeObject(object) {
  const result = {}

  each(object, (value, key) => {
    if (key === 'default_locale') {
      result[key] = value
    } else {
      result[key] = serializer.deserialize(value || '')
    }
  })

  return result
}

function serializeObject(object) {
  const result = {}

  each(object, (value, key) => {
    if (key === 'default_locale') {
      result[key] = value
    } else {
      result[key] = serializer.serialize(value)
    }
  })

  return result
}

export function deserialize(fieldData) {
  if (fieldData.type === 'html') {
    if (fieldData.localized) {
      return deserializeObject(fieldData.value)
    } else {
      return serializer.deserialize(fieldData.value || '')
    }
  }

  return fieldData.value || ''
}

export function serialize(fieldData, value) {
  if (fieldData.type === 'html') {
    if (fieldData.localized) {
      return serializeObject(value)
    } else {
      return serializer.serialize(value)
    }
  }

  return value
}
