import React, { Component } from 'react';
// import 'typename-monkey-patch';
import { ApolloProvider } from "react-apollo";
import { ApolloProvider as ApolloHookProvider } from '@apollo/react-hooks';
import client from './apolloClient';
import { BrowserRouter as Router, Route } from "react-router-dom";

import AppNav from './components/AppNav'
import Authenticate from './components/Authenticate'
import Home from './pages/Home';
import Pages from './pages/Pages';
import Templates from './pages/Templates';
import Files from './pages/Files';
import Content from './pages/Content';
import ContentTypes from './pages/ContentTypes';

import './App.css';

class App extends Component {
  render() {
    return (
      <ApolloProvider client={client}>
        <ApolloHookProvider client={client}>
          <Authenticate>{data => (
            <Router>
              <div className="app">
                <AppNav data={data} />
                <Route exact path='/' component={Home} />
                <Route path='/sites/:siteId/pages' component={Pages} />
                <Route path='/content/:type' component={Content} />
                <Route path='/templates' component={Templates} />
                <Route path='/files' component={Files} />
                <Route path='/content-types' component={ContentTypes} />
              </div>
            </Router>
          )}</Authenticate>
        </ApolloHookProvider>
      </ApolloProvider>
    );
  }
}

export default App;
