import gql from "graphql-tag";

export const page = gql`
  query Page($id: ID!, $siteId: ID!) {
    page(id: $id, siteId: $siteId) {
      id
      siteId
      key
      status
      content {
        id
        key
        label
        localized
        type
        value
      }
    }
  }
`;

export const pages = gql`
  query Pages($siteId: ID!) {
    pages(siteId: $siteId) {
      id
      parentId
      siteId
      key
    }
  }
`;

export const updatePage = gql`
  mutation UpdatePage($id: ID!, $attributes: PageAttributes!) {
    updatePage(id: $id, attributes: $attributes) {
      page {
        id
        siteId
        key
        status
        content {
          id
          key
          type
          value
        }
      }
    }
  }
`;
