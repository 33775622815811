import gql from "graphql-tag";

export const nodeTypes = gql`
  query NodeTypes {
    nodeTypes {
      id
      key
    }
  }
`;

export const updateNodeType = gql`
  mutation UpdateNodeType($id: ID!, $attributes: NodeTypeAttributes!) {
    updateNodeType(id: $id, attributes: $attributes) {
      nodeType {
        id
        key
      }
    }
  }
`;

export const nodeType = gql`
  query NodeType($id: ID!) {
    nodeType(id: $id) {
      id
      key
      schema {
        id
        key
        label
        type
        localized
        display
        source
      }
    }
  }
`;
