import React, { Component } from 'react';
import classNames from 'classnames';

import styles from './Tabs.module.css';

class Button extends Component {
  onClick = (e) => {
    this.props.onClick(this.props.value, e)
  }

  render() {
    return (
      <button className={this.props.className} onClick={this.onClick}>{this.props.children}</button>
    )
  }
}

function Tabs({ mode, onClick }) {
  return (
    <div className="tabs">
      <Button value='html' className={classNames(styles.button, { [styles.active]: mode === 'html'})} onClick={onClick}>HTML</Button>
      <Button value='css' className={classNames(styles.button, { [styles.active]: mode === 'css'})} onClick={onClick}>CSS</Button>
      <Button value='schema' className={classNames(styles.button, { [styles.active] :mode === 'schema'})} onClick={onClick}>Schema</Button>
    </div>
  )
}

export default Tabs;
