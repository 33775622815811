import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import 'react-datepicker/dist/react-datepicker-cssmodules.css';

import styles from "./Forms.module.css";

const parseDate = (value) => {
  if (!value) {
    return null;
  }

  return new Date(value);
};

export const DateControl = ({ onChange, tabIndex, value }) => {
  const handleChange = (v) => {
    onChange(v ? v : "");
  };

  return (
    <DatePicker
      className={styles.control}
      selected={parseDate(value)}
      onChange={handleChange}
      tabIndex={tabIndex}
      isClearable
    />
  );
};

export const DateTimeControl = ({ onChange, tabIndex, value }) => {
  const handleChange = (v) => {
    onChange(v ? v : "");
  };

  return (
    <DatePicker
      className={styles.control}
      selected={parseDate(value)}
      onChange={handleChange}
      showTimeSelect
      timeFormat="HH:mm"
      dateFormat="Pp"
      tabIndex={tabIndex}
      isClearable
    />
  );
};
