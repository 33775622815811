import React from 'react'
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';

import styles from './Buttons.module.css'

export const Button = (props) => (
  <button {...props} error={undefined} className={classNames(styles.button, { [styles.error]: props.error })} />
)

export const NavButton = (props) => (
  <NavLink {...props} className={styles.button} />
)

export default Button
