import React, { Component } from "react";
import classNames from "classnames";
import styles from "./Files.module.css";

class File extends Component {
  onClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    this.props.onClick({
      id: this.props.id,
      uid: this.props.uid,
      filename: this.props.filename,
    });
  };

  render() {
    const { filename, selected, thumbnailUrl, fileUrl } = this.props;

    return (
      <div
        className={classNames(styles.file, { [styles.selected]: selected })}
        onClick={this.onClick}
      >
        <div className={styles.thumbnail}>
          <img src={thumbnailUrl} alt={filename} />
        </div>
        <div className={styles.filename}>
          <a href={fileUrl}>{filename}</a>
        </div>
      </div>
    );
  }
}

export default File;
