import React from 'react'

import styles from './Messages.module.css'

export const Empty = () => (
  <div className={styles.empty}>
    No data
  </div>
)

export const Error = ({ error }) => (
  <div className={styles.error}>
    {error.message}
  </div>
)

export const Loading = () => (
  <div className={styles.loading}>
    Loading...
  </div>
)
