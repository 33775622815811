import React from 'react';
import { NavLink } from "react-router-dom";
import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import styles from './MainMenu.module.css'

const query = gql`
  query MenuItems {
    sites {
      id
      key
    }
    nodeTypes {
      id
      key
    }
  }
`;

const MenuItem = ({ to, children }) => (
  <li><NavLink className={styles.menuItem} activeClassName={styles.active} to={to}>{children}</NavLink></li>
)

const MainMenu = () => {
  const { loading, error, data } = useQuery(query);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error</div>;

  return (
    <nav>
      <p className={styles.menuLabel}>Sites</p>
      <ul className={styles.menuList}>
        {data.sites.map(site => (
          <MenuItem key={site.id} to={`/sites/${site.id}/pages`}>{site.key}</MenuItem>
        ))}
      </ul>
      <p className={styles.menuLabel}>Content</p>
      <ul className={styles.menuList}>
        {data.nodeTypes.map(nodeType => (
          <MenuItem key={nodeType.id} to={`/content/${nodeType.key}`}>{nodeType.key}</MenuItem>
        ))}
      </ul>
      <p className={styles.menuLabel}>Assets</p>
      <ul className={styles.menuList}>
        <MenuItem to="/files">Files</MenuItem>
      </ul>
      <p className={styles.menuLabel}>Settings</p>
      <ul className={styles.menuList}>
        <MenuItem to="/content-types">Content Types</MenuItem>
        <MenuItem to="/templates">Templates</MenuItem>
      </ul>
    </nav>
  )
}

export default MainMenu
