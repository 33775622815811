import React, { Component } from 'react'
import { Textarea } from '../htmlControls'
import { Button } from '../Button'
import classNames from 'classnames'
import styles from './HtmlEmbed.module.css'

class HtmlEmbed extends Component {
  state = {
    editing: false
  }

  onClick = (e) => {
    e.preventDefault()
    this.setState({ editing: !this.state.editing })
  }

  render() {
    const value = this.props.value

    if (this.state.editing) {
      return (
        <React.Fragment>
          <Textarea {...this.props} value={value} className={classNames(this.props.className, styles.textarea )}  />
          <Button onClick={this.onClick}>Preview</Button>
        </React.Fragment>
      )
    }

    return (
      <React.Fragment>
        <div dangerouslySetInnerHTML={{__html: value}} className={styles.preview}/>
        <Button onClick={this.onClick}>Edit</Button>
      </React.Fragment>
    )
  }
}

export default HtmlEmbed
