import React, { Component } from "react";
import { Editor, getEventTransfer } from "slate-react";
import SoftBreak from "slate-soft-break";
import { isKeyHotkey } from "is-hotkey";
import serializer from "./serializer";
import { insertImage, insertEmbed, wrapLink, unwrapLink } from "./helpers";

import FileBrowser from "./FileBrowser";

import {
  MdFormatBold,
  MdFormatItalic,
  MdCode,
  MdLooksOne,
  MdLooksTwo,
  MdLooks3,
  MdFormatQuote,
  MdFormatListNumbered,
  MdFormatListBulleted,
  MdLink,
  MdShare,
} from "react-icons/md";

import { Button } from "./RichTextControls";
import EmbedBlock from "./EmbedBlock";
import ImageBlock from "./ImageBlock";
import TextBlock from "./TextBlock";
import styles from "./RichTextEditor.module.css";

const plugins = [SoftBreak({ shift: true })];

/**
 * Define the default node type.
 *
 * @type {String}
 */

const DEFAULT_NODE = "paragraph";

/**
 * Define hotkey matchers.
 *
 * @type {Function}
 */

const isBoldHotkey = isKeyHotkey("mod+b");
const isItalicHotkey = isKeyHotkey("mod+i");
const isUnderlinedHotkey = isKeyHotkey("mod+u");
const isCodeHotkey = isKeyHotkey("mod+`");

/**
 * The rich text example.
 *
 * @type {Component}
 */

class RichTextEditor extends Component {
  /**
   * The editor's schema.
   *
   * @type {Object}
   */

  schema = {
    blocks: {
      embed: {
        isVoid: true,
      },
      image: {
        isVoid: true,
      },
    },
  };

  /**
   * Check whether the current selection has a link in it.
   *
   * @return {Boolean} hasLinks
   */

  hasInline = (type) => {
    const { value } = this.props;
    return value.inlines.some((inline) => inline.type === type);
  };

  /**
   * Check if the current selection has a mark with `type` in it.
   *
   * @param {String} type
   * @return {Boolean}
   */

  hasMark = (type) => {
    const { value } = this.props;
    return value.activeMarks.some((mark) => mark.type === type);
  };

  /**
   * Check if the any of the currently selected blocks are of `type`.
   *
   * @param {String} type
   * @return {Boolean}
   */

  hasBlock = (type) => {
    const { value } = this.props;
    return value.blocks.some((node) => node.type === type);
  };

  /**
   * Store a reference to the `editor`.
   *
   * @param {Editor} editor
   */

  ref = (editor) => {
    this.editor = editor;
  };

  selectImage = (data) => {
    this.editor.command(insertImage, data);
  };

  /**
   * Render.
   *
   * @return {Element}
   */

  render() {
    if (!this.props.value) {
      return <div>Invalid value sent to editor</div>;
    }

    return (
      <div className={styles.wrapper}>
        <div className={styles.toolbar}>
          {this.renderMarkButton("bold", MdFormatBold)}
          {this.renderMarkButton("italic", MdFormatItalic)}
          {this.renderMarkButton("code", MdCode)}
          {this.renderBlockButton("heading-one", MdLooksOne)}
          {this.renderBlockButton("heading-two", MdLooksTwo)}
          {this.renderBlockButton("heading-three", MdLooks3)}
          {this.renderBlockButton("block-quote", MdFormatQuote)}
          {this.renderBlockButton("numbered-list", MdFormatListNumbered)}
          {this.renderBlockButton("bulleted-list", MdFormatListBulleted)}
          {this.renderInlineButton("link", MdLink)}
          <FileBrowser
            isActive={this.hasBlock("image")}
            onChange={this.selectImage}
          />
          {this.renderBlockButton("embed", MdShare)}
        </div>
        <Editor
          className={styles.editor}
          placeholder="Enter text"
          plugins={plugins}
          spellCheck
          ref={this.ref}
          tabIndex={this.props.tabIndex}
          value={this.props.value}
          onChange={this.onChange}
          onKeyDown={this.onKeyDown}
          onPaste={this.onPaste}
          renderNode={this.renderNode}
          renderMark={this.renderMark}
          schema={this.schema}
        />
      </div>
    );
  }

  /**
   * Render a link toolbar button.
   */
  renderInlineButton = (type, Icon) => {
    const isActive = this.hasInline(type);

    return (
      <Button
        active={isActive}
        onMouseDown={(event) => this.onClickLink(event, type)}
      >
        <Icon />
      </Button>
    );
  };

  /**
   * Render a mark-toggling toolbar button.
   *
   * @param {String} type
   * @param {String} icon
   * @return {Element}
   */

  renderMarkButton = (type, Icon) => {
    const isActive = this.hasMark(type);

    return (
      <Button
        active={isActive}
        onMouseDown={(event) => this.onClickMark(event, type)}
      >
        <Icon />
      </Button>
    );
  };

  /**
   * Render a block-toggling toolbar button.
   *
   * @param {String} type
   * @param {String} icon
   * @return {Element}
   */

  renderBlockButton = (type, Icon) => {
    let isActive = this.hasBlock(type);

    if (["numbered-list", "bulleted-list"].includes(type)) {
      const {
        value: { document, blocks },
      } = this.props;

      if (blocks.size > 0) {
        const parent = document.getParent(blocks.first().key);
        isActive = this.hasBlock("list-item") && parent && parent.type === type;
      }
    }

    return (
      <Button
        active={isActive}
        onMouseDown={(event) => this.onClickBlock(event, type)}
      >
        <Icon />
      </Button>
    );
  };

  /**
   * Render a Slate node.
   *
   * @param {Object} props
   * @return {Element}
   */

  renderNode = (props, editor, next) => {
    const { attributes, children, node } = props;

    switch (node.type) {
      case "block-quote":
        return <TextBlock {...props} tag="blockquote" />;
      case "bulleted-list":
        return <TextBlock {...props} tag="ul" />;
      case "heading-one":
        return <TextBlock {...props} tag="h1" />;
      case "heading-two":
        return <TextBlock {...props} tag="h2" />;
      case "heading-three":
        return <TextBlock {...props} tag="h3" />;
      case "numbered-list":
        return <TextBlock {...props} tag="ol" />;
      case "paragraph":
        return <TextBlock {...props} tag="p" />;
      case "image":
        return <ImageBlock {...props} />;
      case "embed":
        return <EmbedBlock {...props} />;
      case "link":
        return (
          <a {...attributes} href={node.data.get("href")}>
            {children}
          </a>
        );
      case "list-item":
        return <li {...attributes}>{children}</li>;
      default:
        console.warn("unhandled node", node);
        return next();
    }
  };

  /**
   * Render a Slate mark.
   *
   * @param {Object} props
   * @return {Element}
   */

  renderMark = (props, editor, next) => {
    const { children, mark, attributes } = props;

    switch (mark.type) {
      case "bold":
        return <strong {...attributes}>{children}</strong>;
      case "code":
        return <code {...attributes}>{children}</code>;
      case "italic":
        return <em {...attributes}>{children}</em>;
      default:
        return next();
    }
  };

  /**
   * On change, save the new `value`.
   *
   * @param {Editor} editor
   */

  onChange = ({ value }) => {
    this.props.onChange(value);
  };

  /**
   * On key down, if it's a formatting command toggle a mark.
   *
   * @param {Event} event
   * @param {Editor} editor
   * @return {Change}
   */

  onKeyDown = (event, editor, next) => {
    let mark;

    if (isBoldHotkey(event)) {
      mark = "bold";
    } else if (isItalicHotkey(event)) {
      mark = "italic";
    } else if (isUnderlinedHotkey(event)) {
      mark = "underlined";
    } else if (isCodeHotkey(event)) {
      mark = "code";
    } else {
      return next();
    }

    event.preventDefault();
    editor.toggleMark(mark);
  };

  /**
   * When a mark button is clicked, toggle the current mark.
   *
   * @param {Event} event
   * @param {String} type
   */

  onClickMark = (event, type) => {
    event.preventDefault();
    this.editor.toggleMark(type);
  };

  /**
   * When a block button is clicked, toggle the block type.
   *
   * @param {Event} event
   * @param {String} type
   */

  onClickBlock = (event, type) => {
    event.preventDefault();

    const { editor } = this;
    const { value } = editor;
    const { document } = value;

    // Handle everything but list buttons.
    if (type === "image") {
      // TODO open file browser
      const src = window.prompt("Enter the URL of the image:");
      if (!src) return;
      editor.command(insertImage, src);
    } else if (type === "embed") {
      // TODO open dialog
      const src = window.prompt("Enter the embed code:");
      if (!src) return;
      editor.command(insertEmbed, src);
    } else if (type !== "bulleted-list" && type !== "numbered-list") {
      const isActive = this.hasBlock(type);
      const isList = this.hasBlock("list-item");

      if (isList) {
        editor
          .setBlocks(isActive ? DEFAULT_NODE : type)
          .unwrapBlock("bulleted-list")
          .unwrapBlock("numbered-list");
      } else {
        editor.setBlocks(isActive ? DEFAULT_NODE : type);
      }
    } else {
      // Handle the extra wrapping required for list buttons.
      const isList = this.hasBlock("list-item");
      const isType = value.blocks.some((block) => {
        return !!document.getClosest(
          block.key,
          (parent) => parent.type === type
        );
      });

      if (isList && isType) {
        editor
          .setBlocks(DEFAULT_NODE)
          .unwrapBlock("bulleted-list")
          .unwrapBlock("numbered-list");
      } else if (isList) {
        editor
          .unwrapBlock(
            type === "bulleted-list" ? "numbered-list" : "bulleted-list"
          )
          .wrapBlock(type);
      } else {
        editor.setBlocks("list-item").wrapBlock(type);
      }
    }
  };

  /**
   * When clicking a link, if the selection has a link in it, remove the link.
   * Otherwise, add a new link with an href and text.
   *
   * @param {Event} event
   */

  onClickLink = (event) => {
    event.preventDefault();

    const { editor } = this;
    const { value } = editor;
    const hasLinks = this.hasInline("link");

    if (hasLinks) {
      // get the value of the link
      // const href = value.inlines.first().data.get('href'))
      editor.command(unwrapLink);
    } else if (value.selection.isExpanded) {
      const href = window.prompt("Enter the URL of the link:");

      if (href == null) {
        return;
      }

      editor.command(wrapLink, href);
    } else {
      const href = window.prompt("Enter the URL of the link:");

      if (href == null) {
        return;
      }

      const text = window.prompt("Enter the text for the link:");

      if (text == null) {
        return;
      }

      editor
        .insertText(text)
        .moveFocusBackward(text.length)
        .command(wrapLink, href);
    }
  };

  onPaste = (event, editor, next) => {
    const transfer = getEventTransfer(event);
    if (transfer.type !== "html") return next();
    try {
      const { document } = serializer.deserialize(transfer.html);
      editor.insertFragment(document);
    } catch (e) {
      console.error(e);
    }
  };
}

/**
 * Export.
 */

export default RichTextEditor;
