import React, { Component } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import produce from "immer";
import nanoid from "nanoid";

import Button from "./Button";
import Close from "./Close";
import Field from "./Field";
import { push, pull, swap } from "../immutable";
import styles from "./SchemaEditor.module.css";

const CONTROL_TYPES = [
  "string",
  "text",
  "html",
  "html_embed",
  "file",
  "date",
  "datetime",
  "url",
  "image_url",
  "has_one",
  "has_many",
  "checkbox",
];

class SchemaItem extends Component {
  onRemoveItem = (e) => {
    this.props.onRemoveItem(this.props.index, e);
  };

  onChangeItem = (k, v, e) => {
    this.props.onChangeItem(this.props.index, k, v, e);
  };

  render() {
    const { fieldData, index } = this.props;

    const association =
      fieldData.type === "has_one" || fieldData.type === "has_many";

    return (
      <Draggable draggableId={fieldData.id} index={index}>
        {(provided, snapshot) => (
          <div
            className={styles.item}
            ref={provided.innerRef}
            {...provided.draggableProps}
          >
            <div className={styles.handle} {...provided.dragHandleProps} />
            <div className={styles.fields}>
              <Field
                name="key"
                label="Key"
                type="string"
                value={fieldData.key}
                onChange={this.onChangeItem}
              />
              <Field
                name="label"
                label="Label"
                type="string"
                value={fieldData.label}
                onChange={this.onChangeItem}
              />
              <Field
                name="type"
                label="Type"
                type="select"
                options={CONTROL_TYPES}
                onChange={this.onChangeItem}
                value={fieldData.type}
                disabled={association}
              />
              {association && (
                <Field
                  name="source"
                  label="Source"
                  type="string"
                  value={fieldData.source}
                  onChange={this.onChangeItem}
                />
              )}
              {!association && (
                <Field
                  name="localized"
                  label="Localized?"
                  type="checkbox"
                  onChange={this.onChangeItem}
                  value={fieldData.localized}
                />
              )}
              <Field
                name="display"
                label="Display?"
                type="checkbox"
                onChange={this.onChangeItem}
                value={fieldData.display}
              />
            </div>
            <div className={styles.actions}>
              {!association && <Close onClick={this.onRemoveItem} />}
            </div>
          </div>
        )}
      </Draggable>
    );
  }
}

class SchemaEditor extends Component {
  onAddItem = () => {
    this.props.onChange(
      push(this.props.value, { id: nanoid(), key: "", type: "string" })
    );
  };

  onRemoveItem = (i, e) => {
    this.props.onChange(pull(this.props.value, i));
  };

  onChangeItem = (i, k, v, e) => {
    this.props.onChange(
      produce(this.props.value, (draft) => {
        draft[i][k] = v;
      })
    );
  };

  onDragEnd = (result) => {
    const items = swap(
      this.props.value,
      result.source.index,
      result.destination.index
    );

    this.props.onChange(items);
  };

  render() {
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div className={styles.wrapper} ref={provided.innerRef}>
              {this.props.value.map((fieldData, i) => (
                <SchemaItem
                  index={i}
                  key={fieldData.id}
                  fieldData={fieldData}
                  onChangeItem={this.onChangeItem}
                  onRemoveItem={this.onRemoveItem}
                />
              ))}
              <Button onClick={this.onAddItem}>Add</Button>
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
}

export default SchemaEditor;
