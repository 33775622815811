import React from 'react';
import { Route } from "react-router-dom";
import { pageNumber } from '../../lib/queryParams';

import { Main, Body } from '../../components/Layout';
import Blank from '../../components/Blank';
import ContentEditor from './ContentEditor';
import ContentList from './ContentList';

const Content = ({ match }) => (
  <Main>
    <Body>
      <Route path='/content' exact render={() => (
        <Blank title='Content ' />
      )} />
      <Route path='/content/:type' exact render={({ location, match }) => (
        <ContentList type={match.params.type} page={pageNumber(location)} />
      )} />
      <Route path='/content/:type/:id' render={({ history, match }) => (
        <ContentEditor type={match.params.type} history={history} id={match.params.id} />
      )} />
    </Body>
  </Main>
)

export default Content
