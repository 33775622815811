import React from "react";
import { filePreviewUrl } from "../../urls";
import styles from "./Files.module.css";

const fileExtension = (filename) => {
  return filename.split(".").pop();
};

const Preview = (props) => {
  const ext = fileExtension(props.filename);

  if (ext === "mp3") {
    return <div className={styles.fileinfo}>{props.filename}</div>;
  }

  return (
    <div className={styles.thumbnail}>
      <img src={filePreviewUrl(props)} alt={props.filename} />
    </div>
  );
};

export default Preview;
