import queryString from 'query-string';

export const queryParams = (location) => (
  queryString.parse(location.search)
)

export const pageNumber = (location) => {
  const page = queryParams(location).page

  if (page) {
    return parseInt(page)
  } else {
    return 1
  }
}
