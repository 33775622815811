import React from 'react';
import { Query } from "react-apollo";

import { NavButton } from '../../components/Button';
import Pagination from '../../components/Pagination';
import { Empty, Error, Loading } from '../../components/Messages';
import { Page, PageHeader, Actions, PageBody } from '../../components/Layout';
import Search from '../../components/Search';
import Table from '../../components/Table';
import { contents } from './queries';

const ContentList = ({ page, type }) => (
  <Page>
    <PageHeader>
      <h1>{type}</h1>
      <Actions>
        <NavButton to={`/content/${type}/new`}>New</NavButton>
      </Actions>
    </PageHeader>
    <PageBody>
      <Search>{({ search, status }) => (
        <Query query={contents} variables={{ page, type, search, status }}>
          {({ loading, error, data }) => {
            if (loading) return <Loading />;
            if (error) return <Error error={error} />;

            if (data.nodes.length === 0) {
              return <Empty />
            }

            return (
              <div>
                <Table type={type} data={data.nodes} />
                <Pagination count={data.nodeCount} page={page} />
              </div>
            )
          }}
        </Query>
      )}</Search>
    </PageBody>
  </Page>
);

export default ContentList
