import React, { Component } from "react";
import { graphql } from "react-apollo";
import { NavLink } from "react-router-dom";

import { serialize, deserialize } from "../../lib/contentSerializer";
import { Error, Loading } from "../../components/Messages";
import { Page, PageHeader, Actions, PageBody } from "../../components/Layout";
import Field from "../../components/Field";
import formStyles from "../../components/Forms.module.css";
import LocaleSelector from "../../components/LocaleSelector";
import SaveButton from "./SaveContentButton";
import { content } from "./queries";

function serializeContent(data, state) {
  const result = data.map((fieldData) => {
    return {
      id: fieldData.id,
      key: fieldData.key,
      value: serialize(fieldData, state[fieldData.key]),
    };
  });
  return result;
}

class ContentEditor extends Component {
  state = {
    locale: "en",
  };

  onChange = (key, value, e) => {
    this.setState({ [key]: value });
  };

  onSave = (result) => {
    if (this.state.id === "new") {
      const id = result.updateNode.node.id;
      this.props.history.replace(`/content/${this.props.type}/${id}`);
    }
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { loading, node, error } = nextProps.data;

    if (error) {
      return {
        id: null,
        error: true,
        loading: false,
        locale: prevState.locale,
      };
    }

    if (loading) {
      return {
        ...prevState,
        id: null,
        loading: true,
        error: false,
        locale: prevState.locale,
      };
    }

    if (prevState.id !== nextProps.id) {
      const result = {
        id: nextProps.id,
        key: node.key,
        status: node.status,
        tagList: node.tagList,
        loading: false,
        error: false,
        locale: prevState.locale,
      };

      node.data.forEach((fieldData) => {
        result[fieldData.key] = deserialize(fieldData);
      });

      return result;
    }

    return prevState;
  }

  serialize = (e) => {
    return {
      key: this.state.key,
      type: this.props.type,
      tagList: this.state.tagList,
      status: this.state.status,
      data: serializeContent(this.props.data.node.data, this.state),
    };
  };

  render() {
    const { loading, node, error } = this.props.data;

    if (error) {
      return <Error error={error} />;
    }

    if (loading) {
      return <Loading />;
    }

    return (
      <Page>
        <PageHeader>
          <h1>
            <NavLink to={`/content/${this.props.type}`}>
              {this.props.type}
            </NavLink>
            &nbsp;&rsaquo;&nbsp;
            {this.state.key || "new"}
          </h1>
          <Actions>
            <LocaleSelector
              locale={this.state.locale}
              onChange={this.onChange}
            />
            <SaveButton
              data={this.serialize}
              id={this.state.id}
              onSave={this.onSave}
            />
          </Actions>
        </PageHeader>
        <PageBody>
          <form className={formStyles["form"]}>
            {node.data.map((fieldData, i) => (
              <Field
                tabIndex={i + 1}
                name={fieldData.key}
                key={`${node.key}.${fieldData.key}.${i}`}
                label={fieldData.label}
                locale={this.state.locale}
                localized={fieldData.localized}
                onChange={this.onChange}
                type={fieldData.type}
                value={
                  this.state[fieldData.key] === null
                    ? fieldData.value
                    : this.state[fieldData.key]
                }
                source={fieldData.source}
              />
            ))}
            <Field
              tabIndex={51}
              name="key"
              label="Key"
              onChange={this.onChange}
              type="string"
              value={this.state.key}
            />
            <Field
              tabIndex={52}
              name="tagList"
              label="Tags"
              onChange={this.onChange}
              type="tag_list"
              value={this.state.tagList}
            />
            <Field
              tabIndex={53}
              name="status"
              label="status"
              onChange={this.onChange}
              options={["draft", "published", "cancelled"]}
              type="select"
              value={this.state.status}
            />
          </form>
        </PageBody>
      </Page>
    );
  }
}

export default graphql(content)(ContentEditor);
