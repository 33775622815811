import React from 'react';
import { NavLink } from 'react-router-dom';
import { format } from 'date-fns'
import classNames from 'classnames'

import styles from './Table.module.css'
import { filePreviewUrl } from '../urls'

function header(data) {
  return data[0].data.map(item => item.label || item.key)
}

const formatters = {
  date: (value) => (
    format(new Date(value), 'P')
  ),

  datetime: (value) => (
    format(new Date(value), 'Pp')
  ),

  file: (value) => (
    <img alt={value.filename} src={filePreviewUrl(value)} width={100} />
  ),

  image_url: (value) => (
    <img alt={value} src={value} width={100} />
  ),

  has_one: (value) => (
    value.title
  ),

  has_many: (value) => (
    value.map(v => v.title).join(', ')
  )
}

function formatValue(data) {
  let value = data.value

  if (!value) {
    return value
  }

  if (value.default_locale) {
    value = value[value.default_locale]
  }

  const formatter = formatters[data.type]

  if (formatter) {
    return formatter(value)
  } else {
    return String(value)
  }
}

const Table = ({ type, data }) => (
  <div className={styles.wrapper}>
    <table className={styles.table}>
      <thead>
        <tr>
          {header(data).map(key => (
            <th key={key}>{key}</th>
          ))}
          <th>Status</th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        {data.map(item => (
          <tr key={item.id}>
            {item.data.map(cell => (
              <td key={cell.key}>{formatValue(cell)}</td>
            ))}
            <td>
              <span className={classNames(styles.status, styles[item.status])}>{item.status}</span>
            </td>
            <td>
              <div className={styles.actions}>
                <NavLink to={`/content/${type}/${item.id}`}>edit</NavLink>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
)

export default Table
