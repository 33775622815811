import React, { Component } from 'react';

import styles from './Checkbox.module.css';

class Checkbox extends Component {
  onChange = (e) => {
    this.props.onChange(e.target.checked, e)
  }

  render() {
    //console.log(this.props)
    return (
      <div className={styles.wrapper}>
        <input type='checkbox' name={this.props.name} value='1' checked={!!this.props.value} onChange={this.onChange} />
      </div>
    )
  }
}

export default Checkbox
