import React from 'react';
import { NavLink } from "react-router-dom";
import classNames from 'classnames';
import range from 'lodash/range';
import styles from './Pagination.module.css';

const pageSize = 25
const padding = 3

const PageItem = ({ children, active, disabled }) => (
  <li className={classNames(styles.pageItem, { [styles.active]: active, [styles.disabled]: disabled })}>
    {children}
  </li>
)

const NextPageNav = ({ disabled, page }) => {
  if (disabled) {
    return (
      <PageItem disabled>
        <span className={styles.pageLink}>&raquo;</span>
      </PageItem>
    )
  }

  return (
    <PageItem>
      <NavLink className={styles.pageLink} to={`?page=${page + 1}`}>&raquo;</NavLink>
    </PageItem>
  )
}

const PreviousPageNav = ({ disabled, page }) => {
  if (disabled) {
    return (
      <PageItem disabled>
        <span className={styles.pageLink}>&laquo;</span>
      </PageItem>
    )
  }

  return (
    <PageItem>
      <NavLink className={styles.pageLink} to={`?page=${page - 1}`}>&laquo;</NavLink>
    </PageItem>
  )
}

const Spacer = () => (
  <PageItem disabled><span className={styles.pageLink}>&hellip;</span></PageItem>
)

const Page = ({ disabled, page }) => {
  if (disabled) {
    return (
      <PageItem active>
        <span className={styles.pageLink}>{page}</span>
      </PageItem>
    )
  }

  return (
    <PageItem>
      <NavLink className={styles.pageLink} to={`?page=${page}`}>{page}</NavLink>
    </PageItem>
  )
}

const Pagination = ({ count, page }) => {
  const pages = Math.ceil(count / pageSize)

  if (pages < 2) {
    return null
  }

  const minPage = page <= padding ? 1 : page - padding;
  const maxPage = page + padding > pages ? pages + 1 : page + padding + 1;

  const pageItems = range(minPage, maxPage)

  return (
    <ul className={styles.pagination}>
      <PreviousPageNav page={page} disabled={page === 1} />
      {minPage > 1 &&
        <React.Fragment>
          <Page page={1} />
          <Spacer />
        </React.Fragment>
      }
      {pageItems.map(p => <Page key={p} page={p} disabled={page === p} /> )}
      {maxPage <= pages &&
        <React.Fragment>
          <Spacer />
          <Page page={pages} />
        </React.Fragment>
      }
      <NextPageNav page={page} disabled={page === pages} />
    </ul>
  )
}

export default Pagination
