import React, { Component } from 'react';
import classNames from 'classnames';

import { Input } from '../htmlControls'
import styles from './Url.module.css';
import { linkPreviewUrl } from '../../urls'

class ImageInfo extends Component {
  state = {
    error: false
  }

  onError = () => {
    console.log('error!')
    this.setState({ error: true })
  }

  render() {
    if (this.state.error) {
      return (
        <div className={classNames(styles.wrapper, styles.invalid)}>
          Image not found
        </div>
      )
    }

    return (
      <div className={classNames(styles.wrapper, styles.valid)}>
        <div className={styles.image}>
          <img
            className={styles.mediumImage}
            alt='Preview'
            src={this.props.url}
            onError={this.onError}
          />
        </div>
      </div>
    )
  }
}

class UrlInfo extends Component {
  state = {
    data: {},
    loading: false
  }

  componentDidMount() {
    this.setState({ loading: true })
    fetch(
      linkPreviewUrl(this.props.url)
    ).then(
      data => data.json()
    ).then(
      data => this.setState({ loading: false, data })
    ).catch(
      e => this.setState({ loading: false, data: {} })
    )
  }

  render () {
    const { description, image, name, url, status } = this.state.data;

    if (!url) {
      return null
    }

    const statusClass = status === 200 ? styles.valid : styles.invalid;

    return (
      <div className={classNames(styles.wrapper, statusClass)}>
        {image &&
          <div className={styles.image}>
            <img className={styles.smallImage} src={image} alt={name} />
          </div>
        }
        <div className={styles.info}>
          <a className={styles.name} href={url}>{name}</a>
          <div className={styles.description}>{description}</div>
        </div>
      </div>
    )
  }
}

class UrlControl extends Component {
  render() {
    const InfoComponent = this.props.image ? ImageInfo : UrlInfo

    return (
      <React.Fragment>
        <Input {...this.props} image={undefined} type='text' value={this.props.value} />
        <InfoComponent key={this.props.value} url={this.props.value} />
      </React.Fragment>
    )
  }
}

export default UrlControl
