import React, { Component } from 'react';
import { graphql } from "react-apollo";
import parameterize from 'parameterize';

import { serialize, deserialize } from '../../lib/contentSerializer';
import { Error, Loading } from '../../components/Messages';
import { Page, PageHeader, Actions, PageBody } from '../../components/Layout';
import Field from '../../components/Field';
import formStyles from '../../components/Forms.module.css';
import LocaleSelector from '../../components/LocaleSelector';
import SaveButton from './SavePageButton';
import { page } from './queries';

function serializeContent(content, state) {
  const result = content.map(fieldData => {
    return { id: fieldData.id, key: fieldData.key, value: serialize(fieldData, state[fieldData.key]) }
  })
  return result
}

class PagesEditor extends Component {
  state = {
    locale: 'en'
  }

  onChange = (key, value, e) => {
    if (key === 'name') {
      let slug = value

      if (value.default_locale) {
        slug = value[value.default_locale]
      }

      this.setState({ key: parameterize(slug) })
    }

    this.setState({ [key]: value })
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { loading, page, error } = nextProps.data;

    if (error) {
      return {
        id: null,
        error: true,
        loading: false,
        locale: prevState.locale
      }
    }

    if (loading) {
      return {
        ...prevState,
        id: null,
        loading: true,
        error: false,
        locale: prevState.locale
      }
    }

    if (prevState.id !== nextProps.id) {
      const result = {
        id: nextProps.id,
        key: page.key,
        siteId: page.siteId,
        status: page.status,
        loading: false,
        error: false,
        locale: prevState.locale
      }

      page.content.forEach(fieldData => {
        result[fieldData.key] = deserialize(fieldData)
      })

      return result;
    }

    return prevState
  }

  onSave = (result) => {
    if (this.state.id === 'new') {
      const { id, siteId } = result.updatePage.page
      this.props.history.replace(`/sites/${siteId}/pages/${id}`)
    }
  }

  serialize = (e) => {
    return ({
      key: this.state.key,
      status: this.state.status,
      siteId: this.state.siteId,
      content: serializeContent(this.props.data.page.content, this.state)
    })
  }

  render() {
    const { loading, page, error } = this.props.data

    if (error) {
      return <Error error={error} />
    }

    if (loading) {
      return <Loading />
    }

    return (
      <Page>
        <PageHeader>
          <h1>Pages &rsaquo; {page.key}</h1>
          <Actions>
            <LocaleSelector locale={this.state.locale} onChange={this.onChange} />
            <SaveButton data={this.serialize} id={this.state.id} onSave={this.onSave} />
          </Actions>
        </PageHeader>

        <PageBody>
          <form className={formStyles['form']}>
            {page.content.map((fieldData, i) => (
              <Field
                tabIndex={i + 1}
                name={fieldData.key}
                key={`${page.key}.${fieldData.key}`}
                label={fieldData.label}
                locale={this.state.locale}
                localized={fieldData.localized}
                onChange={this.onChange}
                type={fieldData.type}
                value={this.state[fieldData.key] || fieldData.value }
              />
            ))}
            <Field
              name='key'
              label='Key'
              onChange={this.onChange}
              tabIndex={51}
              type='string'
              value={this.state.key}
            />
            <Field
              tabIndex={53}
              name='status'
              label='Status'
              onChange={this.onChange}
              options={['draft', 'published']}
              type='select'
              value={this.state.status}
            />
          </form>
        </PageBody>
      </Page>
    )
  }
}

export default graphql(page)(PagesEditor)
