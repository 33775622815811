import React, { Component } from 'react'
import classNames from 'classnames'
import Close from './Close';
import styles from './Search.module.css';
import formStyles from './Forms.module.css';

class Search extends Component {
  state = {
    value: '',
    searchValue: '',
    status: ''
  }

  onClear = (e) => {
    this.setState({
      value: '',
      searchValue: ''
    })
  }

  onChange = (e) => {
    this.setState({ value: e.target.value })
  }

  onKeyPress = (e) => {
    if (e.key === 'Enter'){
      this.setState({ searchValue: this.state.value })
    }
  }

  onStatusChange = (e) => {
    this.setState({ status: e.target.value })
  }

  render() {
    return (
      <React.Fragment>
        <div className={styles.wrapper}>
          <label className={styles.label}>Search: </label>
          <div className={styles.control}>
            <input className={styles.input} type="text" value={this.state.value} onChange={this.onChange} onKeyPress={this.onKeyPress} />
            {this.state.value && <Close onClick={this.onClear} />}
          </div>
          <label className={styles.label}>Status: </label>
          <select className={classNames(formStyles.control, styles.select)} onChange={this.onStatusChange} value={this.state.status}>
            <option value=''>All</option>
            <option value='draft'>Draft</option>
            <option value='published'>Published</option>
          </select>
        </div>
        {this.props.children({ search: this.state.searchValue, status: this.state.status })}
      </React.Fragment>
    )
  }
}

export default Search
