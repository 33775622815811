const fileRegExp = /(.*)\.(.*)/

export const apiUrl = process.env.REACT_APP_API_URL

export const graphqlUrl = `${apiUrl}/graphql`

export const loginUrl = `${apiUrl}/login`

export const filePreviewUrl = (data) => {
  const parts = data.uid.match(fileRegExp)

  return `${apiUrl}/files/${parts[1]}/${data.filename}`
}

export const linkPreviewUrl = (url) => (
  `${apiUrl}/previews/${btoa(url)}`
)
