import React from 'react';
import { DateControl, DateTimeControl } from './DatePicker'
import { Select, Textarea, Input } from './htmlControls'
import RichTextEditor from './editors/RichTextEditor'
import FileBrowser from './controls/FileBrowser'
import Association from './controls/Association';
import Taglist from './controls/Taglist';
import Checkbox from './controls/Checkbox';
import UrlControl from './controls/Url';
import HtmlEmbed from './controls/HtmlEmbed';

const mapping = {
  string: 'text',
  text: 'textarea'
}

function Control(props) {
  const type = mapping[props.type] || props.type
  const value = props.value || ''

  if (type === 'date') {
    return (
      <DateControl
        name={props.name}
        onChange={props.onChange}
        tabIndex={props.tabIndex}
        value={value}
      />
    )
  }

  if (type === 'datetime') {
    return (
      <DateTimeControl
        name={props.name}
        onChange={props.onChange}
        tabIndex={props.tabIndex}
        value={value}
      />
    )
  }

  if (type === 'file') {
    return (
      <FileBrowser
        name={props.name}
        onChange={props.onChange}
        tabIndex={props.tabIndex}
        value={value}
      />
    )
  }

  if (type === 'select') {
    return (
      <Select
        className={props.className}
        name={props.name}
        onChange={props.onChange}
        tabIndex={props.tabIndex}
        value={value}
        disabled={props.disabled}
      >
        {(props.options || []).map(option => (
          <option key={option}>{option}</option>
        ))}
      </Select>
    )
  }

  if (type === 'tag_list') {
    return <Taglist tabIndex={props.tabIndex} onChange={props.onChange} source={props.source} value={value} />
  }

  if (type === 'textarea') {
    return <Textarea {...props} value={value} />
  }

  if (type === 'html') {
    return <RichTextEditor name={props.name} tabIndex={props.tabIndex} onChange={props.onChange} value={value} />
  }

  if (type === 'has_one') {
    return <Association  tabIndex={props.tabIndex} onChange={props.onChange} source={props.source} value={value} />
  }

  if (type === 'has_many') {
    return <Association  tabIndex={props.tabIndex} multiple onChange={props.onChange} source={props.source} value={value} />
  }

  if (type === 'html_embed') {
    return <HtmlEmbed {...props} value={value} />
  }

  if (type === 'image_url') {
    return <UrlControl image {...props} value={value} />
  }

  if (type === 'url') {
    return <UrlControl {...props} value={value} />
  }

  if (type === 'checkbox') {
    return <Checkbox {...props} value={value} />
  }

  return (
    <Input {...props} type={type} value={value} />
  )
}

export default Control
