import React, { Component } from 'react'
import { graphql } from "react-apollo";
import gql from "graphql-tag";

import Uploader from '../../components/Uploader';

class UploadButton extends Component {
  onUpload = (data) => {
    this.props.mutate({
      variables: {
        attributes: data
      }
    })
  }

  render() {
    return (
      <Uploader name='file' onChange={this.onUpload} />
    )
  }
}

const mutation = gql`
  mutation UploadFile($attributes: FileAttributes!) {
    uploadFile(attributes: $attributes) {
      file {
        id
      }
    }
  }
`;

const refetchQueries = ['Files']

export default graphql(mutation, { options: { refetchQueries }})(UploadButton)
