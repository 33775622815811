import { filePreviewUrl } from '../../urls'

/**
 * A change function to standardize inserting images.
 *
 * @param {Editor} editor
 * @param {String} src
 * @param {Range} target
 */

export function insertImage(editor, data, target) {
  if (target) {
    editor.select(target)
  }
  const src = filePreviewUrl(data)

  editor.insertBlock({
    type: 'image',
    data: { src, ...data },
  })
}

export function insertEmbed(editor, src, target) {
  if (target) {
    editor.select(target)
  }

  editor.insertBlock({
    type: 'embed',
    data: { src },
  })
}

/**
 * A change helper to standardize wrapping links.
 *
 * @param {Editor} editor
 * @param {String} href
 */

export function wrapLink(editor, href) {
  editor.wrapInline({
    type: 'link',
    data: { href },
  })

  editor.moveToEnd()
}

/**
 * A change helper to standardize unwrapping links.
 *
 * @param {Editor} editor
 */

export function unwrapLink(editor) {
  editor.unwrapInline('link')
}
