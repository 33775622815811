import React from 'react'
import ReactDOM from 'react-dom'
import Close from './Close'
import styles from './Modal.module.css'

const Modal = ({ open, children, onClose, title }) => {
  if (!open) {
    return null
  }

  return ReactDOM.createPortal(
    <div className={styles.container}>
      <div className={styles.backdrop} onClick={onClose} />
      <div className={styles.wrapper}>
        <header className={styles.header}>
          <div className={styles.title}>{title}</div>
          <Close onClick={onClose} />
        </header>
        <div className={styles.body}>
          {children}
        </div>
      </div>
    </div>,
    document.body
  )
}

export default Modal
