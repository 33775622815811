import React, { Component } from 'react';

function withChangeValue(WrappedComponent) {
  return class extends Component {
    onChange = (e) => {
      const value = e.target.value
      this.props.onChange(value, e)
    }

    render() {
      return (
        <WrappedComponent {...this.props} onChange={this.onChange} />
      )
    }
  }
}

export const Input = withChangeValue('input')
export const Textarea = withChangeValue('textarea')
export const Select = withChangeValue('select')
