import React from 'react';
import Select from 'react-select/lib/AsyncCreatable';
import gql from 'graphql-tag';
import { withApollo } from 'react-apollo';

import styles from './Taglist.module.css';

export const query = gql`
  query Tags($search: String) {
    tags(search: $search) {
      name
    }
  }
`;

class Taglist extends React.Component {
  state = {
    loading: false
  }

  loadOptions = (search) => {
    this.setState({ loading: true })
    return this.props.client.query({
      query, variables: { search }
    }).then(result => {
      this.setState({ loading: false })
      return result.data.tags.map(node => (
        { value: node.name, label: node.name }
      ))
    })
  }

  onChange = (selection) => {
    if (selection.length > 0) {
      this.props.onChange(selection.map(s => s.label))
    } else {
      this.props.onChange(selection)
    }
  }

  render() {
    const value = this.props.value

    let defaultValue

    defaultValue = value.map(v => ({ value: v, label: v }))

    return (
      <Select
        className={styles.wrapper}
        defaultOptions
        defaultValue={defaultValue}
        isClearable
        isLoading={this.state.loading}
        isMulti
        loadOptions={this.loadOptions}
        onChange={this.onChange}
        tabIndex={this.props.tabIndex}
      />
    )
  }
}

export default withApollo(Taglist)
