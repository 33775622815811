import React from 'react'
import styled from '@emotion/styled'
import Button from '../Button'

const Wrapper = styled('div')`
  position: relative;
  padding: .25rem;
  outline: ${props => (props.selected ? '1px solid #ccc' : '')};
`

const Container = styled('div')`
  iframe {
    display: block;
    width: 100%;
  }
`

const Mask = styled('div')`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  cursor: default;
`

const Textarea = styled('textarea')`
  width: 100%;
  height: 10em;
  font-family: Consolas, "Andale Mono WT", "Andale Mono", "Lucida Console", "Lucida Sans Typewriter", "DejaVu Sans Mono", "Bitstream Vera Sans Mono", "Liberation Mono", "Nimbus Mono L", Monaco, "Courier New", Courier, monospace;
  font-size: 1rem;
`

const Toolbar = styled('div')`
  padding-top: .25rem;
`

export default class EmbedBlock extends React.Component{
  state = {
    editing: false,
    src: null
  }

  onEditMode = (e) => {
    e.preventDefault()
    this.setState({ editing: true, src: this.props.node.data.get('src') })
  }

  onEditClick = (e) => {
    e.stopPropagation()
  }

  onRemoveClick = (e) => {
    e.preventDefault()

    const { node, editor } = this.props

    editor.removeNodeByKey(node.key)
  }

  onSaveClick = (e) => {
    e.preventDefault()

    const src = this.state.src
    const { node, editor } = this.props

    editor.setNodeByKey(node.key, { data: { src } })
    this.setState({ editing: false, src: null })
  }

  onEditChange = (e) => {
    this.setState({ src: e.target.value })
  }

  render() {
    return this.state.editing ? this.renderEdit() : this.renderPreview()
  }

  renderEdit() {
    const {attributes, isSelected} = this.props

    return (
      <Wrapper {...attributes} selected={isSelected}>
        <Textarea onClick={this.onEditClick} onChange={this.onEditChange} value={this.state.src} />
        <Toolbar>
          <Button onClick={this.onSaveClick}>Save</Button>
        </Toolbar>
      </Wrapper>
    )
  }

  renderPreview() {
    const {attributes, isSelected, node} = this.props

    const src = node.data.get('src')

    return (
      <Wrapper {...attributes} selected={isSelected}>
        {!isSelected && <Mask />}
        <Container dangerouslySetInnerHTML={{__html: src}} />
        <Toolbar>
          <Button onClick={this.onEditMode}>Edit</Button>
          <Button onClick={this.onRemoveClick}>Remove</Button>
        </Toolbar>
      </Wrapper>
    )
  }
}

//
