import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { NavLink } from "react-router-dom";
import { getTreeFromFlatData } from 'react-sortable-tree';

import styles from './NavPane.module.css';

const NavList = ({ items, root }) => {
  if (!items) {
    return null;
  }

  return (
    <ul className={styles.list}>
      {items.map(({ id, children, key }) => (
        <NavItem root={root} key={id} id={id} title={key} children={children} />
      ))}
    </ul>
  )
}

const NavItem = ({ root, id, children, title }) => {
  return (
    <li className={styles.item}>
      <NavLink className={styles.link} activeClassName={styles.active} to={`/${root}/${id}`}>{title}</NavLink>
      <NavList root={root} items={children} />
    </li>
  )
}

const NavPane = ({ query, dataRoot, urlRoot, tree, variables }) => {
  const { loading, error, data } = useQuery(query, { variables })

  if (loading) return null;
  if (error) return <div>Error</div>;

  let itemData = data[dataRoot]

  if (tree) {
    itemData = getTreeFromFlatData({ flatData: itemData })
  }

  return (
    <div className="main-nav">
      <nav className={styles.wrapper}>
        <NavList root={urlRoot} items={itemData} />
        <ul className={styles.list}>
          <li className={styles.item}>
            <NavLink className={styles.link} activeClassName={styles.active} to={`/${urlRoot}/new`}>+ new</NavLink>
          </li>
        </ul>
      </nav>
    </div>
  )
}

export default NavPane
