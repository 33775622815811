import React, { Component } from 'react'
import Uppy from '@uppy/core'
import AwsS3Multipart from '@uppy/aws-s3-multipart'
import { DragDrop } from '@uppy/react'
import { apiUrl } from '../urls'
import '@uppy/core/dist/style.css'
import '@uppy/drag-drop/dist/style.css'
import './Uploader.css'

class Uploader extends Component {
  constructor(props) {
    super(props)

    this.uppy = Uppy({
      meta: { type: 'file' },
      autoProceed: true
    })

    this.uppy.use(AwsS3Multipart, {
      limit: 4,
      serverUrl: apiUrl
    })

    this.uppy.on('upload-success', (file, data) => {
      const uploadedFileData = {
        id: data.uploadURL.match(/\/cache\/([^?]+)/)[1], // extract key without prefix
        storage: 'cache',
        metadata: {
          size:      file.size,
          filename:  file.name,
          mimeType: file.type,
        }
      }

      props.onChange(uploadedFileData)
    })
  }

  render() {
    return (
      <div tabIndex={this.props.tabIndex} className='uploader'>
        {this.props.value &&
          <div>ID: {this.props.value.id}</div>
        }
        <DragDrop
          uppy={this.uppy}
          width='200px'
        />
      </div>
    )
  }
}

export default Uploader
