import React, { Component } from 'react'
import gql from "graphql-tag";
import { Query } from "react-apollo";

import File from './File'
import UploadButton from './UploadButton'
import Pagination from '../Pagination'

import styles from './Files.module.css'

const query = gql`
  query Files($page: Int) {
    files(page: $page) {
      id
      uid
      filename
      thumbnailUrl
      fileUrl
    }
    fileCount
  }
`;

const Files = ({ data, onClick, selected }) => {
  return data.map(file => (
    <File
      key={file.id}
      onClick={onClick}
      selected={selected && file.id === selected.id}
      {...file}
    />
  ))
}

class FileList extends Component {
  render() {
    return (
      <Query query={query} variables={{ page: this.props.page }}>
        {({ loading, error, data }) => {
          if (loading) return <div>Loading...</div>;
          if (error) return <div>Error</div>;

          return (
            <React.Fragment>
              <div className={styles.files} onClick={this.props.onClearClick}>
                <div className={styles.file}>
                  <UploadButton />
                </div>
                <Files data={data.files} onClick={this.props.onClick} selected={this.props.selected} />
              </div>
              <Pagination count={data.fileCount} page={this.props.page} />
            </React.Fragment>
          )
        }}
      </Query>
    )
  }
}

export default FileList
