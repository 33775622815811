import React, { Component } from 'react';
import { graphql } from "react-apollo";

import { Page, PageHeader, PageNav, Actions, PageBody } from '../../components/Layout';
import Form from '../../components/Form'
import Field from '../../components/Field'
import CodeEditor from '../../components/CodeEditor';
import SchemaEditor from '../../components/SchemaEditor';
import Tabs from '../../components/Tabs';
import SaveButton from './SaveTemplateButton';
import { template } from './queries';

const editors = {
  html: CodeEditor,
  css: CodeEditor,
  data: CodeEditor,
  schema: SchemaEditor
}

class TemplateEditor extends Component {
  state = {
    id: null,
    loading: false,
    mode: 'html',
    html: '',
    css: '',
    schema: [],
    key: ''
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { loading, template } = nextProps.data;

    if (loading) {
      return {
        ...prevState,
        id: null,
        loading: true,
      }
    }

    if (prevState.id !== nextProps.id) {
      return {
        mode: prevState.mode,
        id: nextProps.id,
        html: template.html,
        css: template.css,
        key: template.key,
        schema: template.schema || [],
        loading: false,
      }
    }

    return prevState
  }

  onKeyChange = (name, value, e) => {
    this.setState({ key: value })
  }

  onChange = (value, e) => {
    this.setState({ [this.state.mode]: value })
  }

  onChangeMode = (value, e) => {
    this.setState({ mode: value })
  }

  onSave = (result) => {
    if (this.state.id === 'new') {
      const id = result.updateTemplate.template.id
      this.props.history.replace(`/templates/${id}`)
    }
  }

  render () {
    const value = this.state[this.state.mode] || '';
    const data = {
      html: this.state.html || '',
      css: this.state.css || '',
      key: this.state.key,
      schema: this.state.schema
    }

    const Editor = editors[this.state.mode]

    return (
      <Page>
        <PageHeader>
          <h1>Templates &rsaquo; {this.state.key || 'New'}</h1>
          <Actions>
            <SaveButton id={this.props.id} data={data} onSave={this.onSave} />
          </Actions>
        </PageHeader>
        <PageNav>
          <Tabs mode={this.state.mode} onClick={this.onChangeMode} />
        </PageNav>
        <PageBody>
          {this.state.mode === 'schema' && (
            <Form>
              <Field
                name='key'
                label='Key'
                onChange={this.onKeyChange}
                type='string'
                value={this.state.key}
              />
            </Form>
          )}
          <Editor
            onChange={this.onChange}
            value={value}
          />
        </PageBody>
      </Page>
    )
  }
}

export default graphql(template)(TemplateEditor)
