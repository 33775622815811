import React from 'react';
import NavPane from '../../components/NavPane';
import { nodeTypes } from './queries';

const ContentTypesMenu = () => (
  <NavPane
    query={nodeTypes}
    dataRoot='nodeTypes'
    urlRoot='content-types'
  />
);

export default ContentTypesMenu
