import React, { Component } from 'react';
import classNames from 'classnames'
import Control from './Control';

import styles from './Forms.module.css';

class Field extends Component  {
  onChange = (value, e) => {
    if (this.props.localized) {
      const newValue = Object.assign({}, this.props.value, { [this.props.locale]: value })
      if (!newValue.default_locale) {
        newValue.default_locale = this.props.locale
      }
      this.props.onChange(this.props.name, newValue, e)
    } else {
      this.props.onChange(this.props.name, value, e)
    }
  }

  render() {
    let value = this.props.value

    if (this.props.localized) {
      if (value) {
        value = value[this.props.locale] || value[value.default_locale]
      }
    }

    return (
      <div className={styles.field}>
        <label
          className={classNames(styles.label)}
        >
          {this.props.label}
          {this.props.localized && <small>&nbsp;{this.props.locale}</small>}
        </label>
        <div className={styles.controlWrapper}>
          <Control
            className={styles.control}
            name={this.props.name}
            onChange={this.onChange}
            options={this.props.options}
            tabIndex={this.props.tabIndex}
            type={this.props.type}
            value={value}
            source={this.props.source}
            disabled={this.props.disabled}
          />
        </div>
      </div>
    )
  }
}

export default Field
