import gql from 'graphql-tag';

export const content = gql`
  query Node($type: String!, $id: ID!) {
    node(type: $type, id: $id) {
      id
      key
      status
      tagList
      data: formData {
        id
        key
        label
        type
        value
        source
        localized
      }
    }
  }
`;

export const contents = gql`
  query Contents($type: String!, $page: Int, $search: String, $status: String) {
    nodeCount(type: $type, search: $search, status: $status)
    nodes(type: $type, page: $page, search: $search, status: $status) {
      id
      key
      status
      data: listData {
        id
        key
        type
        label
        value
        localized
      }
    }
  }
`;

export const contentsOverview = gql`
  query Content($type: String!) {
    nodes(type: $type) {
      id
      key
    }
  }
`;

export const updateNode = gql`
  mutation UpdateNode($id: ID!, $attributes: NodeAttributes!) {
    updateNode(id: $id, attributes: $attributes) {
      node {
        id
        key
        tagList
        data {
          id
          key
          type
          value
          source
          localized
        }
      }
    }
  }
`;
