import gql from "graphql-tag";

export const template = gql`
  query Template($id: ID!) {
    template(id: $id) {
      id
      key
      html
      css
      schema {
        id
        key
        label
        localized
        type
      }
    }
  }
`;

export const templates = gql`
  query Templates {
    templates {
      id
      key
    }
  }
`;

export const updateTemplate = gql`
  mutation UpdateTemplate($id: ID!, $attributes: TemplateAttributes!) {
    updateTemplate(id: $id, attributes: $attributes) {
      template {
        id
        key
        html
        css
        schema {
          id
          key
          label
          localized
          type
        }
      }
    }
  }
`;
