import styled from '@emotion/styled'

export const Button = styled('div')`
  display: inline-block;
  cursor: pointer;
  font-size: 18px;
  height: 20px;
  padding: 1px;
  color: ${props => (props.active ? 'white' : 'var(--special-color)')};
  background-color: ${props => (props.active ? 'var(--special-color)' : 'white')};
  border-radius: .25rem;
`
