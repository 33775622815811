import React from 'react'

import styles from './Forms.module.css'

const Form = ({ children }) => (
  <div className={styles.form}>
    {children}
  </div>
)

export default Form
