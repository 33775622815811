import React from 'react';
import { Route } from "react-router-dom";

import { Main, Body } from '../../components/Layout';
import { pageNumber } from '../../lib/queryParams';

import FileBrowser from './FileBrowser';

export default () => (
  <Main>
    <Body>
      <Route path='/files/' exact render={({ location }) => (
        <FileBrowser page={pageNumber(location)} />
      )} />
    </Body>
  </Main>
)
