import React from 'react';
import Select from 'react-select/lib/Async';
import gql from 'graphql-tag';
import { withApollo } from 'react-apollo';

import styles from './Association.module.css';

export const query = gql`
  query Content($type: String!, $search: String) {
    nodes(type: $type, search: $search) {
      id
      name
    }
  }
`;

class Association extends React.Component {
  state = {
    loading: false
  }

  loadOptions = (search) => {
    this.setState({ loading: true })
    return this.props.client.query({
      query, variables: { type: this.props.source, search }
    }).then(result => {
      this.setState({ loading: false })
      return result.data.nodes.map(node => (
        { value: node.id, label: node.name }
      ))
    })
  }

  onChange = (selection) => {
    console.log('on change')
    if (this.props.multiple) {
      if (selection.length > 0) {
        this.props.onChange(selection.map(s => ({
          id: s.value,
          name: s.label
        })))
      } else {
        this.props.onChange(selection)
      }
    } else {
      if (selection) {
        this.props.onChange({
          id: selection.value,
          name: selection.label
        })
      } else {
        this.props.onChange(selection)
      }
    }
  }

  render() {
    const value = this.props.value

    let defaultValue

    if (this.props.multiple) {
      defaultValue = value.map(v => ({ value: v.id, label: v.name }))
    } else if (value.id) {
      defaultValue = { value: value.id, label: value.name }
    }

    return (
      <Select
        className={styles.wrapper}
        defaultOptions
        defaultValue={defaultValue}
        isClearable
        isLoading={this.state.loading}
        isMulti={this.props.multiple}
        loadOptions={this.loadOptions}
        onChange={this.onChange}
        tabIndex={this.props.tabIndex}
      />
    )
  }
}

export default withApollo(Association)
