import React from 'react';
import { Main, Body } from '../components/Layout';
import { Page, PageHeader, PageBody } from '../components/Layout';

export default () => (
  <Main>
    <Body>
      <Page>
        <PageHeader>
          <h1>Home</h1>
        </PageHeader>
        <PageBody>


        </PageBody>
      </Page>
    </Body>
  </Main>
)
