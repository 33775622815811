import React from 'react'
// import { Link } from "react-router-dom";
import classNames from 'classnames';

import MainMenu from './MainMenu'

import styles from './AppNav.module.css'

const AppNav = ({ data }) => (
  <header className={classNames('app-nav', styles.wrapper)}>
    <a className={styles.accountInfo} href='/'>
      <div className={styles.brand}>{data.account.name}</div>
      <div className={styles.user}>{data.viewer.name}</div>
    </a>
    <MainMenu />
  </header>
)

export default AppNav
