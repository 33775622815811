import React from 'react';
import { Route } from "react-router-dom";

import { Main, Body } from '../../components/Layout';
import Blank from '../../components/Blank';
import TemplatesMenu from './TemplatesMenu';
import TemplateEditor from './TemplateEditor';

export default () => (
  <Main>
    <TemplatesMenu />
    <Body>
      <Route path='/templates' exact render={() => (
        <Blank title='Templates' />
      )} />
      <Route path='/templates/:id' render={({match}) => (
        <TemplateEditor id={match.params.id} />
      )} />
    </Body>
  </Main>
)
