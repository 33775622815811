import React, { Component } from 'react';

import Button from '../Button'
import Modal from '../Modal'
import FileList from '../files/FileList'
import Preview from '../files/Preview'

import { Footer, Actions } from '../Layout';

class FileBrowser extends Component {
  state = { open: false, selected: null }

  onSelectClick = (selected) => {
    if (this.state.selected === selected) {
      this.setState({ selected: null })
    } else {
      this.setState({ selected })
    }
  }

  onClearClick = () => {
    this.setState({ selected: null })
  }

  onBrowseClick = (e) => {
    e.preventDefault()
    this.setState({ open: !this.state.open })
  }

  onClose = (e) => {
    this.setState({ open: false })
  }

  onInsertClick = (e) => {
    this.props.onChange(this.state.selected)
    this.setState({ open: false, selected: null })
  }

  onRemoveClick = (e) => {
    e.preventDefault()
    this.props.onChange(null)
    this.setState({ selected: null })
  }

  render() {
    return (
      <div>
        { this.props.value &&
          <Preview {...this.props.value} />
        }
        <Button onClick={this.onBrowseClick} tabIndex={this.props.tabIndex}>Select...</Button>
        { this.props.value &&
          <Button onClick={this.onRemoveClick}>Remove</Button>
        }
        <Modal open={this.state.open} onClose={this.onClose} title='Browse'>
          <FileList
            selected={this.state.selected}
            onClick={this.onSelectClick}
            onClearClick={this.onClearClick}
          />
          <Footer>
            <Actions>
              <Button onClick={this.onInsertClick}>Select</Button>
            </Actions>
          </Footer>
        </Modal>
      </div>
    )
  }
}

export default FileBrowser
