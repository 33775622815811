import ApolloClient from "apollo-boost";
import { graphqlUrl } from './urls.js'

const client = new ApolloClient({
  uri: graphqlUrl,
  credentials: 'include',
  headers: {
    Authorization: `Bearer d2766c21-d2f4-48a7-bd22-00a894e58f93`
  }
});

export default client;
