import React from 'react'
import styled from '@emotion/styled'

const Image = styled('img')`
  cursor: default;
  display: block;
  max-width: 100%;
  max-height: 600px;
  padding: .25rem;
  outline: ${props => (props.selected ? '1px solid #ccc;' : '')};
`

export default ({ node, attributes, isSelected }) => {
  const src = node.data.get('src')

  return (
    <Image {...attributes} selected={isSelected} src={src} />
  )
}
