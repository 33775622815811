import React from 'react';
import classNames from 'classnames';

import styles from './Layout.module.css'

export const Main = ({ children }) => (
  <div className='main'>
    {children}
  </div>
)

export const Header = ({ children }) => (
  <header className={classNames('main-header', styles.header)}>
    {children}
  </header>
)

export const Footer = ({ children }) => (
  <footer className={styles.footer}>
    {children}
  </footer>
)

export const Actions = ({ children }) => (
  <div className={styles.actions}>
    {children}
  </div>
)


export const Body = ({ children }) => (
  <div className='main-body'>
    {children}
  </div>
)

// const Content = ({ children }) => (
//   <div className='main-content'>
//     {children}
//   </div>
// )

export const Page = ({ children }) => (
  <div className='page'>
    {children}
  </div>
)

export const PageHeader = ({ children }) => (
  <div className={classNames('page-header', styles.header)}>
    {children}
  </div>
)

export const PageBody = ({ children }) => (
  <div className='page-body'>
    {children}
  </div>
)

export const PageNav = ({ children }) => (
  <div className='page-nav'>
    {children}
  </div>
)
