export function clone(array) {
  return [...array]
}

export function pop(array) {
  return array.slice(0, -1)
}

export function pull(array, index) {
  return [...array.slice(0, index), ...array.slice(index + 1)]
}

export function push(array, item) {
  return [...array, item]
}

export function shift(array){
  return array.slice(1)
}

export function swap(array, startIndex, endIndex) {
  const result = Array.from(array);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
}

export function unshift(array, item){
  return [item, ...array]
}
