import React, { Component } from 'react';
import { graphql } from "react-apollo";

import { Page, Actions, PageHeader, PageBody } from '../../components/Layout'
import Form from '../../components/Form'
import Field from '../../components/Field'
import SchemaEditor from '../../components/SchemaEditor'
import { Loading, Error } from '../../components/Messages'
import { nodeType } from './queries'
import SaveButton from './SaveContentTypeButton'

class ContentTypeEditor extends Component {
  state = {
    id: null,
    loading: false,
    schema: [],
    key: ''
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { loading, error, nodeType } = nextProps.data;

    // TODO if prevState.id === 'new' and nextProps.data.loading === true
    // we should just update the id and avoid refreshing the whole UI

    if (loading) {
      return {
        ...prevState,
        id: null,
        loading: true,
      }
    }

    if (error) {
      return {
        id: null,
        loading: false,
        error: true
      }
    }

    if (prevState.id !== nextProps.id) {
      return {
        mode: prevState.mode,
        id: nextProps.id,
        key: nodeType.key,
        schema: nodeType.schema ? nodeType.schema.map(item => { delete item.__typename; return item }) : [],
        loading: false,
      }
    }

    return prevState
  }

  onKeyChange = (name, value, e) => {
    this.setState({ [name]: value })
  }

  onSchemaChange = (value, e) => {
    this.setState({ schema: value })
  }

  onSave = (result) => {
    if (this.state.id === 'new') {
      const id = result.updateNodeType.nodeType.id
      this.props.history.replace(`/content-types/${id}`)
    }
  }

  render() {
    const { loading, error } = this.props.data
    const data = {
      key: this.state.key,
      schema: this.state.schema
    }

    if (error) {
      return <Error error={error} />
    }

    if (loading) {
      return <Loading />
    }

    return (
      <Page>
        <PageHeader>
          <h1>Content Types &rsaquo; {this.state.key || 'New'}</h1>
          <Actions>
            <SaveButton id={this.props.id} data={data} onSave={this.onSave} />
          </Actions>
        </PageHeader>
        <PageBody>
          <Form>
            <Field
              name='key'
              label='Key'
              onChange={this.onKeyChange}
              type='string'
              value={this.state.key}
            />
          </Form>
          <SchemaEditor
            onChange={this.onSchemaChange}
            value={this.state.schema}
          />
        </PageBody>
      </Page>
    )
  }
}


export default graphql(nodeType)(ContentTypeEditor)
