import React from 'react';
import MonacoEditor from 'react-monaco-editor';

function CodeEditor(props) {
  return (
    <MonacoEditor {...props} />
  );
}

export default CodeEditor;
